import { DayFragment, DayStatusEnum } from 'src/app/graphql/generated';

export function dayIsApprovable(day: DayFragment): boolean {
  return day && day.status === DayStatusEnum.OPEN && dayHasData(day);
}

export function dayHasData(day: DayFragment): boolean {
  return !!(
    day.hoursEntries.filter((entry) => entry.duration && !entry.deletedAt)
      .length > 0 ||
    day.expenseUUID ||
    day.absenceUUID
  );
}
