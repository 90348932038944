import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalService, ResponsiveService } from '@intemp/unijob-ui';
import { HoursEntryFragment, HoursEntry } from 'src/app/graphql/generated';
import { getDurationSeconds } from '../../../../../shared/helpers/documents/getDurationSeconds';
import { getDayTimeNumber } from '../../../../../shared/helpers/functions/date-helpers/getDayTimeNumber';
import { getDayTimeDifferenceSeconds } from '../../../../../shared/helpers/functions/getDayTimeDifferenceSeconds';
import { parseDayTime } from '../../../../../shared/helpers/functions/parseDayTime';
import { parseDuration } from '../../../../../shared/helpers/functions/parseDuration';
import { randomId } from '../../../../../shared/helpers/functions/randomId';
import { customValidators } from '../../../../../shared/modules/shared-forms/customValidators';
import {
  EditHoursFormGroup,
  HoursEntriesFormArray,
} from '../../../employment-types/employment-form';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-edit-hours-row',
  templateUrl: './edit-hours-row.component.html',
  styleUrls: ['./hours-row-shared.scss'],
})
export class EditHoursRowComponent implements OnInit, OnChanges, OnDestroy {
  @Input({ required: true }) entry!: HoursEntryFragment;
  @Input({ required: true }) formReferenceArray!: HoursEntriesFormArray;
  @Input() isDefaultEntry = false;

  @Output() removeEntry = new EventEmitter();

  @Input() isDisabled = false;

  @Input() canAddAdjustment = false;

  @Output() validateOverlappingEntries = new EventEmitter();

  oneOfTheInputsIsFocused = false;

  fromInput$ = new BehaviorSubject<string | null>(null);
  toInput$ = new BehaviorSubject<string | null>(null);
  pauseInput$ = new BehaviorSubject<string | null>(null);
  noteInput$ = new BehaviorSubject<string | null>(null);

  uuidControl = new FormControl<string | null>(null);
  fromControl = new FormControl<string | null>(null, {
    validators: [customValidators.isDayTime],
  });
  toControl = new FormControl<string | null>(null, {
    validators: [customValidators.isDayTime],
  });
  pauseControl = new FormControl<string | null>(null, {
    validators: [customValidators.isTimeDuration],
  });
  noteControl = new FormControl<string | null>(null);
  editEntryFormGroup: EditHoursFormGroup = new FormGroup(
    {
      uuid: this.uuidControl,
      from: this.fromControl,
      to: this.toControl,
      pause: this.pauseControl,
      note: this.noteControl,
    },
    { updateOn: 'submit' },
  );
  public modals: ModalService;
  isNightShift = false;
  deleteModalInitialized = false;
  deleteModalId = randomId();
  noteModalId = randomId();

  isMobileView$ = this.responsiveService.isSmDown$;

  entriesAreEmpty$ = new BehaviorSubject(true);

  constructor(
    private modalService: ModalService,
    private responsiveService: ResponsiveService,
  ) {
    this.modals = modalService;
  }

  deleteEntry() {
    if (this.entry.createdAt) {
      this.openDeleteModal();
    } else {
      this.removeEntry.emit();
      this.editEntryFormGroup?.markAsPristine();
    }
  }

  setFocused(): void {
    this.oneOfTheInputsIsFocused = true;
  }

  setUnfocused(): void {
    this.oneOfTheInputsIsFocused = false;
  }

  ngOnInit(): void {
    this.attachFormReference();
    this.validateEntriesState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entry) {
      this.patchEntry(changes.entry.currentValue);
    }
  }

  patchEntry(entry: HoursEntry): void {
    const parsedFrom =
      entry.from !== undefined && entry.from !== null
        ? parseDayTime(entry.from.toString())
        : null;
    const parsedTo =
      entry.to !== undefined && entry.to !== null
        ? parseDayTime(entry.to.toString())
        : null;
    const parsedPause =
      entry.pause !== undefined && entry.pause !== null
        ? parseDuration((entry.pause / 60)?.toString() + 'm' ?? '', 'auto')
        : null;

    this.editEntryFormGroup.patchValue(
      {
        uuid: entry.uuid,
        from: parsedFrom,
        to: parsedTo,
        pause: parsedPause,
        note: entry.note,
      },
      { emitEvent: false },
    );
    this.fromInput$.next(parsedFrom);
    this.toInput$.next(parsedTo);
    this.pauseInput$.next(parsedPause);
    this.noteInput$.next(entry.note);

    this.checkNightShift();
  }

  updateInput(key: 'from' | 'to' | 'pause' | 'note', event?: Event): void {
    const value = (event?.target as HTMLInputElement).value;
    switch (key) {
      case 'from':
        this.fromInput$.next(parseDayTime(value));
        break;
      case 'to':
        this.toInput$.next(parseDayTime(value));
        break;
      case 'pause':
        this.pauseInput$.next(parseDuration(value, 'auto'));
        break;
      case 'note':
        this.noteInput$.next(value);
        break;
    }

    const inputsAreValid = this.runCustomValidator(
      this.fromInput$.value,
      this.toInput$.value,
      this.pauseInput$.value,
    );

    this.validateEntriesState();
    this.checkNightShift();

    if (!inputsAreValid) return;

    this.updateEntry(
      this.fromInput$.value,
      this.toInput$.value,
      this.pauseInput$.value,
      this.noteInput$.value,
    );
    this.updateTotal();
    this.emitChanges();
  }

  areEntriesEmpty() {
    return (
      !this.fromInput$.value &&
      !this.toInput$.value &&
      !this.pauseInput$.value &&
      !this.noteInput$.value
    );
  }

  validateEntriesState(): void {
    const inputsAreEmpty = this.areEntriesEmpty();
    this.entriesAreEmpty$.next(inputsAreEmpty);

    if (!this.entry.createdAt && inputsAreEmpty) {
      this.editEntryFormGroup.setErrors(null);
      this.editEntryFormGroup.markAsPristine();
    }
  }

  updateEntry(
    from: string | null,
    to: string | null,
    pause: string | null,
    note: string | null,
  ) {
    this.entry.from = getDayTimeNumber(from ?? '');
    this.entry.to = getDayTimeNumber(to ?? '');
    this.entry.pause = getDurationSeconds(pause ?? '');
    this.entry.note = note;
  }

  emitChanges(): void {
    this.editEntryFormGroup.markAsDirty();
    this.validateOverlappingEntries.emit();
  }

  runCustomValidator(
    fromValue: string | null,
    toValue: string | null,
    pauseValue: string | null,
  ): boolean {
    // reset errors
    this.editEntryFormGroup.setErrors(null);
    this.pauseControl.setErrors(null);

    if (fromValue === '' || fromValue === null) {
      this.editEntryFormGroup.setErrors({
        message: { key: 'missingFromTime' },
      });
      return false;
    }

    if (!toValue) {
      this.editEntryFormGroup.setErrors({
        message: { key: 'missingToTime' },
      });
      return false;
    }

    if (fromValue === toValue) {
      this.editEntryFormGroup.setErrors({
        message: { key: 'startAndEndTimeMustBeDifferent' },
      });
      return false;
    }

    if (pauseValue && (!fromValue || !toValue)) {
      this.editEntryFormGroup.setErrors({
        message: { key: 'missingDuration' },
      });
      return false;
    }

    const pauseInSeconds = getDurationSeconds(pauseValue ?? '0');

    const duration = this.calculateTotalDuration(
      fromValue,
      toValue,
      pauseInSeconds,
    );
    if (duration <= 0) {
      this.pauseControl.setErrors({
        message: {
          key: 'workDurationMustBeGreaterThanPauseDuration',
        },
      });
      return false;
    }
    return true;
  }

  attachFormReference(): void {
    if (this.formReferenceArray) {
      // adds itself to day form control
      this.formReferenceArray.push(this.editEntryFormGroup);
    }
  }
  //previousVersion has same fields as HoursEntryFragment
  previousVersionTypeCast<T>(item: T) {
    return item as HoursEntryFragment;
  }

  checkNightShift(): void {
    this.isNightShift = !!(
      this.entry.from &&
      this.entry.to &&
      this.entry.from > this.entry.to
    );
  }

  calculateTotalDuration(
    from: string | number,
    to: string | number,
    pause: number | null,
  ): number {
    let duration = getDayTimeDifferenceSeconds(from, to);
    if (pause) {
      duration -= pause;
    }
    return duration;
  }

  updateTotal(): void {
    if (
      this.entry.from !== undefined &&
      this.entry.from !== null &&
      this.entry.to !== undefined &&
      this.entry.to !== null
    ) {
      let duration = getDayTimeDifferenceSeconds(
        this.entry.from,
        this.entry.to,
      );
      if (this.entry.pause) {
        duration -= this.entry.pause;
      }
      this.entry.duration = duration;
    }
  }

  async openNoteModal(): Promise<void> {
    this.modals.open(this.noteModalId);
  }

  openDeleteModal(): void {
    if (this.deleteModalInitialized) {
      this.modals.open(this.deleteModalId);
    } else {
      this.deleteModalInitialized = true;
      window.requestAnimationFrame(() => {
        this.modals.open(this.deleteModalId);
      });
    }
  }

  ngOnDestroy(): void {
    if (this.formReferenceArray) {
      const entryFormIndex = this.formReferenceArray.controls.findIndex(
        (item) => item.controls.uuid.value === this.entry?.uuid,
      );
      if (entryFormIndex !== -1) {
        this.editEntryFormGroup?.markAsPristine();
        this.formReferenceArray.removeAt(entryFormIndex);
      }
    }
  }

  trackByUUID(index: number, item: any) {
    return item.uuid;
  }
}
