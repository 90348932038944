import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ResponsiveService, ToastService, unijobIcon } from '@intemp/unijob-ui';
import { I18NextPipe } from 'angular-i18next';
import { getDay, isAfter, isFuture } from 'date-fns';
import { BehaviorSubject } from 'rxjs';
import { UserService } from 'src/app/models/shared/user/user.service';
import { getHoursEntryPause } from 'src/app/shared/helpers/functions/getHoursEntryPause';
import {
  DayFragment,
  DayStatusEnum,
  EmploymentFragment,
  HoursEntryFragment,
} from 'src/app/graphql/generated';
import { EmploymentService } from '../../../../models/uniweb/employment/employment.service';
import { parseDayDateString } from '../../../../shared/helpers/functions/date-helpers/parseDayDateString';
import { hourEntryHasData } from '../../../../shared/helpers/functions/employment-input/hourEntryHasData';
import { parseDuration } from '../../../../shared/helpers/functions/parseDuration';
import { randomId } from '../../../../shared/helpers/functions/randomId';
import {
  DayFormGroup,
  DaysFormArray,
  EditHoursFormGroup,
  HoursEntriesFormArray,
} from '../../employment-types/employment-form';
import { dayIsEmpty } from '../../../../shared/helpers/functions/dayIsEmpty';

export type DayAddMenuOption = {
  label: string;
  iconName?: unijobIcon;
  action: () => void;
};

export interface HoursEntryWithPause {
  hoursEntry: HoursEntryFragment[];
  pauseEntry: Record<number, string>;
}

@Component({
  selector: 'app-open-day-list-item',
  templateUrl: './open-day.component.html',
  styleUrls: ['./open-day.component.scss'],
})
export class OpenDayComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('addMenuButton') addMenuButton?: ElementRef;

  @Input() inactive = false;
  @Input({ required: true }) formReferenceArray!: DaysFormArray;
  @Input({ required: true }) day!: DayFragment;
  @Input({ required: true }) employment!: EmploymentFragment;
  @Input() isEvenListItem = false;
  @Input({ required: true }) isCompanyUser = false;

  @Output() editReportClick = new EventEmitter();
  @Output() editAbsenceClick = new EventEmitter();
  @HostBinding('class.new-week')
  isNewWeek = false;
  isInitialized = false;

  editDayFormGroup?: DayFormGroup;
  formHoursEntries?: HoursEntriesFormArray;

  loadingFinished = false;
  addMenuOptions: DayAddMenuOption[] = [
    {
      action: this.addHoursRow.bind(this),
      iconName: 'clock_stop_watch',
      label: 'hours',
    },
    {
      action: this.addExpenseRow.bind(this),
      iconName: 'money',
      label: 'expenses',
    },
    {
      action: this.addAbsenceRow.bind(this),
      iconName: 'travel_airplane_holiday',
      label: 'absence',
    },
  ];

  totalHours = '0';
  dayIsEmpty = new BehaviorSubject<boolean>(false);
  showFromToPauseHeadings = true;

  sortedHoursEntries: HoursEntryWithPause = {
    hoursEntry: [],
    pauseEntry: {},
  };

  loggedInUserEmail?: string;

  dayIsInTheFuture = false;

  public dayStatusEnum = DayStatusEnum;
  constructor(
    public userService: UserService,
    private employmentService: EmploymentService,
    private toastService: ToastService,
    private i18nPipe: I18NextPipe,
    public responsiveService: ResponsiveService,
  ) {}

  ngOnInit(): void {
    this.userService.user$?.subscribe((user) => {
      if (user) {
        this.loggedInUserEmail = user.profile.email;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.inactive) {
      this.init();
    }
    if (changes.day) {
      this.prepareEntries();
    }
  }

  ngOnDestroy() {
    if (this.formReferenceArray) {
      const entryFormIndex = this.formReferenceArray.controls.findIndex(
        (item: FormGroup) => item === this.editDayFormGroup,
      );
      if (entryFormIndex !== -1) {
        this.formReferenceArray.removeAt(entryFormIndex);
      }
    }
  }

  init(): void {
    this.isNewWeek = getDay(parseDayDateString(this.day.date)) === 0;
    if (!this.isInitialized) {
      this.createFormGroup();
      this.isInitialized = true;
      window.requestAnimationFrame(() => {
        this.loadingFinished = true;
      });
    }
    this.createDefaultEntry();
  }

  prepareEntries() {
    if (this.isCompanyUser) {
      this.sortHoursEntriesForCustomer();
    } else {
      this.sortHoursEntriesForEmployee();
    }
    this.checkIfDayIsEmpty();
    this.setPauseEntry();
    this.setTotalHours();
    this.setDayInFuture();
  }

  setDayInFuture() {
    this.dayIsInTheFuture = isFuture(this.day.date);
    this.showFromToPauseHeadings = !this.dayIsInTheFuture;
  }

  checkIfDayIsEmpty() {
    const isEmpty = dayIsEmpty(this.day);
    this.dayIsEmpty.next(isEmpty);

    if (this.day.status === DayStatusEnum.APPROVED) {
      const hasNonHoursEntry =
        (this.day.hoursEntries.length === 0 &&
          (this.day.absenceUUID || this.day.expenseUUID)) ||
        isEmpty;
      this.showFromToPauseHeadings = !hasNonHoursEntry;
    }

    if (this.day.reportUUID) {
      this.showFromToPauseHeadings = false;
    }
  }

  createDefaultEntry(): void {
    if (this.day.status === DayStatusEnum.APPROVED) {
      return;
    }
    const hoursEntries = this.day.hoursEntries?.filter((h) => !h.deletedAt);
    if (hoursEntries.length > 0) {
      return;
    }
    if (!this.day.reportUUID) {
      this.createHoursEntry(this.day);
    }
  }

  setTotalHours(): void {
    const activeHoursEntries = this.day.hoursEntries.filter(
      (day) => !day.deletedAt && day.duration,
    );
    let durationSum = 0;
    activeHoursEntries.forEach((entry) => {
      durationSum += entry.duration ? entry.duration : 0;
    });
    this.totalHours = parseDuration(durationSum / 60);
  }

  createHoursEntry(day: DayFragment): void {
    day.hoursEntries.push({
      uuid: randomId(),
    } as HoursEntryFragment);
  }

  validateDay(): void {
    this.sortHoursEntries();
    const hasOverlappingEntry = this.checkOverlappingHoursEntries();
    if (!hasOverlappingEntry) {
      this.editDayFormGroup?.controls.hoursEntries.markAsDirty();
      this.editDayFormGroup?.controls.hoursEntries.updateValueAndValidity();
    }
  }

  sortHoursEntries(): void {
    this.day.hoursEntries.sort((a, b) =>
      a.from !== undefined &&
      a.from !== null &&
      b.from !== undefined &&
      b.from !== null
        ? a.from - b.from
        : // keep order if anything is null or undefined
          0,
    );
  }

  checkOverlappingHoursEntries(): boolean {
    if (!this.editDayFormGroup) return false;
    const hoursEntryFormGroups =
      this.editDayFormGroup.controls.hoursEntries.controls;

    return this.day.hoursEntries.some(
      (hoursEntry: HoursEntryFragment, index) => {
        const nextEntry = this.day.hoursEntries[index + 1];
        if (!nextEntry) {
          return false;
        }
        if (
          !hoursEntry.from ||
          !hoursEntry.to ||
          !nextEntry.from ||
          !nextEntry.to
        ) {
          return false;
        }
        const hasOverlappingEntry =
          nextEntry.from >= hoursEntry.from && nextEntry.from < hoursEntry.to;
        let formGroup = hoursEntryFormGroups.find(
          (hoursFormGroup) =>
            hoursFormGroup.controls.uuid.value === nextEntry.uuid,
        );

        if (!formGroup) {
          // if the formGroup is not found, means the nextEntry is a new entry
          formGroup = hoursEntryFormGroups[0];
        }
        formGroup.setErrors(null);
        formGroup.controls.to.setErrors(null);
        formGroup.controls.from.setErrors(null);
        if (hasOverlappingEntry) {
          formGroup.controls.from.setErrors({ invalid: true });
          formGroup.controls.to.setErrors({ invalid: true });
          formGroup.markAsDirty();
          formGroup.setErrors({
            message: {
              key: 'overlappingHoursRows',
            },
            overlappingHoursRows: true,
          });
          return true;
        } else {
          return false;
        }
      },
    );
  }

  createFormGroup(): void {
    this.formHoursEntries = new FormArray<EditHoursFormGroup>([]);
    this.editDayFormGroup = new FormGroup({
      date: new FormControl(this.day.date || null, []),
      hoursEntries: this.formHoursEntries,
    });
    if (this.formReferenceArray) {
      const entryFormIndex = this.formReferenceArray.controls.findIndex(
        (item: FormGroup) => item.controls.date.value === this.day.date,
      );
      // makes sure every day is only in the form once
      if (entryFormIndex === -1) {
        this.formReferenceArray.push(this.editDayFormGroup);
      }
    }
  }

  clearEntry(entry: HoursEntryFragment): void {
    entry.from = null;
    entry.to = null;
    entry.pause = null;
    entry.duration = 0;
    const formGroup = this.formHoursEntries?.controls.find(
      (control) => control.controls.uuid.value === entry.uuid,
    );
    if (formGroup) {
      formGroup.controls.from.setValue(null);
      formGroup.controls.to.setValue(null);
      formGroup.controls.pause.setValue(null);
    }
  }

  removeEntry(entry: HoursEntryFragment): void {
    if (!hourEntryHasData(entry)) {
      const index = this.day.hoursEntries.findIndex(
        (e) => e.uuid === entry.uuid,
      );

      const hasOtherEntries = this.day.hoursEntries.length > 1;
      if (!hasOtherEntries && index === 0) {
        this.clearEntry(entry);
      } else if (index > -1) {
        this.day.hoursEntries.splice(index, 1);
      }
      return;
    }

    if (!entry.createdAt) {
      this.clearEntry(entry);
    }

    entry.deletedAt = new Date().toISOString();
    if (entry.previousVersions?.length) {
      const versions = entry.previousVersions;
      const previousVersion = versions.pop();
      if (previousVersion) {
        const restoredEntry: HoursEntryFragment = {
          ...previousVersion,
          previousVersions: versions,
        };
        const entryIndex = this.day.hoursEntries.findIndex(
          (e) => e.uuid === entry.uuid,
        );
        this.day.hoursEntries[entryIndex] = restoredEntry;
        entry = restoredEntry;
      }
    }

    if (entry.createdAt) {
      this.employmentService
        .updateEntry(this.employment, entry, this.day)
        .subscribe(() => {
          this.toastService.makeToast({
            type: 'SUCCESS',
            message: this.i18nPipe.transform('entryRemoved'),
          });
        });
    } else {
      this.toastService.makeToast({
        type: 'SUCCESS',
        message: this.i18nPipe.transform('entryRemoved'),
      });
    }
    this.day.hoursEntries = this.day.hoursEntries.filter(
      (e) => e.uuid !== entry.uuid,
    );
  }

  addHoursRow(): void {
    this.day.hoursEntries.push({
      uuid: randomId(),
    } as HoursEntryFragment);
    this.prepareEntries();
  }

  addAbsenceRow(): void {
    this.employmentService.absenceEntryToEdit$.next({
      entryId: this.day.absenceUUID || randomId(),
      dates: [this.day.date, this.day.date],
    });
  }

  addExpenseRow(): void {
    this.employmentService.expenseEntryToEdit$.next({
      entryId: this.day.expenseUUID || randomId(),
      date: this.day.date,
    });
  }

  editExpenses(entryId: string): void {
    this.employmentService.expenseEntryToEdit$.next({
      entryId: entryId,
      date: this.day.date,
    });
  }

  editAbsence(absenceId: string): void {
    this.employmentService.absenceEntryToEdit$.next({
      entryId: absenceId,
      dates: [this.day.date, this.day.date],
    });
  }

  trackByUuid(index: number, item: HoursEntryFragment): string {
    return item.uuid;
  }

  setPauseEntry() {
    this.sortedHoursEntries.pauseEntry = {};
    this.sortedHoursEntries.hoursEntry.forEach((entry, index) => {
      const pause = getHoursEntryPause(
        index,
        this.sortedHoursEntries.hoursEntry,
      );
      if (pause) {
        this.sortedHoursEntries.pauseEntry[index] = pause;
      }
    });
  }

  sortHoursEntriesForEmployee() {
    this.sortedHoursEntries.hoursEntry = this.day.hoursEntries.sort((a, b) => {
      if (!a.createdBy || !b.createdBy) {
        return 1;
      }
      const fromA = a.from || 0;
      const fromB = b.from || 0;
      return fromA < fromB ? -1 : 1;
    });
  }

  // customer specific sorting, because as UI/UX requirement
  // when customer makes adjustments to the hoursEntry, the new Entry need to be on top of the list
  sortHoursEntriesForCustomer() {
    this.sortedHoursEntries.hoursEntry = this.day.hoursEntries
      .sort((a, b) => {
        let aDate = new Date(a.createdAt);
        let bDate = new Date(b.createdAt);
        if (a.previousVersions?.length > 0 && b.previousVersions?.length > 0) {
          aDate = new Date(
            a.previousVersions[a.previousVersions.length - 1].createdAt,
          );
          bDate = new Date(
            b.previousVersions[b.previousVersions.length - 1].createdAt,
          );
        } else if (
          a.previousVersions?.length > 0 &&
          b.previousVersions?.length === 0
        ) {
          aDate = new Date(
            a.previousVersions[a.previousVersions.length - 1]?.createdAt,
          );
        } else if (
          a.previousVersions?.length === 0 &&
          b.previousVersions?.length > 0
        ) {
          bDate = new Date(
            b.previousVersions[b.previousVersions.length - 1]?.createdAt,
          );
        }
        return isAfter(aDate, bDate) ? 1 : -1;
      })
      .sort((a, b) => {
        const fromA = a.from || 0;
        const fromB = b.from || 0;
        return fromA - fromB;
      });
  }
}
