<div class="uj-flex uj-flex-col uj-gap-8">
  <div
    class="uj-px-20 uj-py-8 uj-rounded-md uj-border-[1px] uj-border-border"
    *ngIf="displayEndDate && employment.endDate">
    <span class="text-m uj-text-text-grey">
      {{ "end" | i18next | firstUppercase }} {{ employment.endDate | date }}
    </span>
  </div>
  <div class="uj-flex uj-flex-col uj-gap-8">
    <div
      *ngFor="
        let month of months;
        let i = index;
        trackBy: trackByMonthStartUnix
      ">
      <app-month-list-item
        (collapseToggled)="toggleMonthCollapsed($event)"
        [month]="month"
        [employment]="employment"
        [sortAsc]="sortAsc"
        [formReferenceArray]="formReferenceArray"
        [initialCollapse]="!month.isCurrentMonth"></app-month-list-item>
    </div>
  </div>
  <div
    class="uj-px-20 uj-py-8 uj-rounded-md uj-border-[1px] uj-border-border"
    *ngIf="employment.startDate">
    <span class="text-m uj-text-text-grey">
      {{ "start" | i18next | firstUppercase }}
      {{ employment.startDate | date }}
    </span>
  </div>
</div>
