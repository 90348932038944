import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { getISOWeek, isFuture } from 'date-fns';
import { DayFragment, DayStatusEnum } from 'src/app/graphql/generated';
import { getDayDateString } from '../../../../../shared/helpers/functions/date-helpers/getDayDateString';
import { parseDayDateString } from '../../../../../shared/helpers/functions/date-helpers/parseDayDateString';
import { dayIsEmpty } from '../../../../../shared/helpers/functions/dayIsEmpty';

@Component({
  selector: 'app-day-info',
  templateUrl: './day-info.component.html',
  styleUrls: ['./day-info.component.scss'],
})
export class DayInfoComponent implements OnChanges {
  @Input({ required: true }) day!: DayFragment;
  @Input() isCompanyUser = false;

  dayStatusEnum = DayStatusEnum;
  isToday = false;
  isoWeek?: number;

  entryWasAdjusted = false;
  hoursEntryAreNotEmpty = false;
  dayIsInTheFuture = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.day) {
      this.isoWeek = getISOWeek(parseDayDateString(this.day.date));
      if (getDayDateString(new Date()) === this.day.date) {
        this.isToday = true;
      }
      this.dayIsInTheFuture = isFuture(this.day.date);

      if (!this.isCompanyUser)
        this.entryWasAdjusted = this.day.hoursEntries.some(
          (entry) => entry.previousVersions?.length > 0,
        );

      if (this.isCompanyUser && this.day.status === DayStatusEnum.OPEN) {
        this.hoursEntryAreNotEmpty = !dayIsEmpty(this.day);
      } else {
        this.hoursEntryAreNotEmpty = false;
      }
    }
  }
}
