import { DayFragment, DayStatusEnum } from 'src/app/graphql/generated';
import { dayHasData } from './dayIsApprovable';
import { isFuture } from 'date-fns';

export function getDaysToApprove(days: DayFragment[]): DayFragment[] {
  return days.filter((day) => {
    return (
      !isFuture(day.date) &&
      day.status === DayStatusEnum.OPEN &&
      dayHasData(day)
    );
  });
}
